import { FORMAT_DD_MM_YYYY } from "@/SupportingFiles/HelpingFunction";
import { Box } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { subDays, subMonths, subYears } from "date-fns";
import { useEffect, useState } from "react";

interface EVDateInputProps {
  id?: string; // Added id prop
  title?: string;
  value: string | null;
  attrName: any;
  value_update: Function;
  error_message?: string;
  warn_status?: boolean;
  className?: string;
  min_date?: number;
  max_date?: number;
  date_validation_type?: "YEARS" | "MONTHS" | "DAYS" | "DATE";
  disabled?: boolean;
}

const EVDateInput: React.FC<EVDateInputProps> = ({
  id, // Added id to destructuring
  title,
  value,
  value_update,
  attrName,
  error_message = "Select",
  warn_status = false,
  className = "",
  min_date = 15,
  max_date = 18,
  date_validation_type = "YEARS",
  disabled = false,
}) => {
  const [minDate, setMinDate] = useState<Date | undefined>(undefined);
  const [maxDate, setMaxDate] = useState<Date | undefined>(undefined);
  const [localValue, setLocalValue] = useState<Date | null>(null);

  useEffect(() => {
    switch (date_validation_type) {
      case "YEARS":
        setMinDate(subYears(new Date(), min_date));
        setMaxDate(subYears(new Date(), max_date));
        break;
      case "MONTHS":
        setMinDate(subMonths(new Date(), min_date));
        setMaxDate(subMonths(new Date(), max_date));
        break;
      case "DAYS":
        setMinDate(subDays(new Date(), min_date));
        setMaxDate(subDays(new Date(), max_date));
        break;
    }
  }, [min_date, max_date, date_validation_type]);

  useEffect(() => {
    if (value) {
      const parts = value.split("-");
      if (parts.length === 3) {
        const formattedDateString = `${parts[2]}-${parts[1]}-${parts[0]}`;
        const parsedDate = new Date(formattedDateString);
        if (!isNaN(parsedDate.getTime())) {
          setLocalValue(parsedDate);
        } else {
          console.error("EVDateInput: Invalid date string");
        }
      } else {
        console.error("EVDateInput: Invalid date format");
      }
    } else {
      setLocalValue(null);
    }
  }, [value]);

  const componentId = id || title || "date-picker";

  return (
    <Box className={`evInputField ${className}`.trim()}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          label={title}
          disabled={disabled}
          value={localValue}
          onChange={(selectedDate) => {
            if (selectedDate) {
              value_update(
                attrName,
                FORMAT_DD_MM_YYYY(selectedDate.toString())
              );
            }
          }}
          format="dd-MM-yyyy"
          closeOnSelect
          minDate={minDate}
          maxDate={maxDate}
          slotProps={{
            textField: {
              id: componentId, // Correctly pass id to textField
            },
          }}
        />
        {warn_status && <span className="error">{error_message}</span>}
      </LocalizationProvider>
    </Box>
  );
};

export default EVDateInput;
