import { Facility, Meals, Policy, Reviews, Rules, TSpaces } from "@/app/page";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FilterOutlinedIcon from "@mui/icons-material/FilterOutlined";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import { Box, Button, Link, Rating } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Grid from "@mui/material/Grid2";
import { SelectChangeEvent } from "@mui/material/Select";
import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Footer from "../../Components/Desktop/Footer/Footer";
import Buttons from "../../Components/InputFields/Buttons/Buttons";
import EVDateInput from "../../Components/InputFields/DatePicker/DatePicker";
import SelectDropdown from "../../Components/InputFields/SelectDropdown/SelectDropdown";
import TabButton from "../../Components/InputFields/TabButton/TabButton";
import Navbar from "../../Components/Mobile/Navbar/Navbar";
import { COLORS } from "../../SupportingFiles/colors";
import MPhotoTourPopup from "./MPhotoTourPopup";

const MDaCations = ({
  handleModifyDetailOpenModal,
  handleModifyDetailCloseModal,
  isModifyDetailModalOpen,
  Reviews,
  explore_your_stay,
  Spaces,
  facilities,
  policy,
  rules,
  faqs,
  meals,
  formData,
  noofRooms,
  noofguests,
  handleChangeReserve,
  validate_form,
  shareLink,
}: {
  handleModifyDetailOpenModal: () => void;
  handleModifyDetailCloseModal: () => void;
  isModifyDetailModalOpen: boolean;
  explore_your_stay: JSX.Element;
  facilities: Facility[];
  Spaces: TSpaces[];
  meals: Meals[];
  rules: Rules[];
  policy: Policy[];
  Reviews: Reviews[];
  faqs: Array<any>;
  formData: {
    checkIn: { value: Date | null | any; warning: boolean };
    checkOut: { value: string | null | Date | any; warning: boolean };
    noOfRooms: { value: string | any; warning: boolean };
    noOfGuests: { value: string | any; warning: boolean };
  };
  setFormData: Function;
  noofRooms: { value: number; label: string }[];
  noofguests: { value: number; label: string }[];
  handleChangeReserve: Function;
  validate_form: () => void;
  openWhatsApp: () => void;
  shareLink: () => void;
}) => {
  const headerSlider = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 2000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 2000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const spaceSlider = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 2000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 2000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const reviewslider = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 2000 },
      items: 2,
    },
    desktop: {
      breakpoint: { max: 2000, min: 1024 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const [guests, setGuests] = React.useState("");

  const handleChange = (event: SelectChangeEvent) => {
    setGuests(event.target.value as string);
  };

  const [rooms, setRooms] = React.useState("");
  const handleRoom = (event: SelectChangeEvent) => {
    setRooms(event.target.value as string);
  };

  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChangeFaq =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const [showRulesContent, setShowRulesContent] = useState(false);
  const [showPolicyContent, setShowPolicyContent] = useState(false);
  const [showDescContent, setShowDescContent] = useState(false);

  const handleScrollTo = (sectionId: string) => {
    setActiveTab(sectionId);
    const section = document.getElementById(sectionId);
    if (section) {
      const topOffset = section.offsetTop - 120; // Adjust the offset as needed
      window.scrollTo({ top: topOffset, behavior: "smooth" });
    }
  };

  const [activeTab, setActiveTab] = useState("");
  useEffect(() => {
    const sectionIds = [
      "About",
      "Spaces",
      "Amenities",
      "Meals",
      "Experiences",
      "Rules",
      "Location",
      "Reviews",
      "Policy",
      "FAQ",
    ];

    const handleScroll = () => {
      for (const sectionId of sectionIds) {
        const section = document.getElementById(sectionId);
        if (section) {
          const { top, height } = section.getBoundingClientRect();
          if (top <= 180 && top + height > 180) {
            setActiveTab(sectionId);
            break;
          }
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        gap="40"
        alignItems="center"
        width="100%"
        padding="100px 12px 0px"
      >
        <Navbar />

        <Box maxWidth="1400px" width="100%">
          <Grid container spacing={0} marginBottom="12px">
            <Grid size={12} className="mheaderSlider">
              <img
                src="./images/dacations-logo.svg"
                height="60px"
                alt="Salud by DaCations"
                style={{
                  position: "absolute",
                  top: "-30px",
                  zIndex: "91",
                  left: "calc(50% - 30px)",
                }}
              />
              <Box
                onClick={() => handleModifyDetailOpenModal()}
                sx={{
                  backgroundColor: "rgba(255,255,255,0.2)",
                  borderRadius: "8px",
                  padding: "4px 8px",
                  position: "absolute",
                  bottom: "24px",
                  right: "24px",
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  color: COLORS.white,
                  zIndex: "91",
                  cursor: "pointer",
                }}
              >
                <FilterOutlinedIcon style={{ fontSize: "14px" }} />
                <p style={{ color: COLORS.white, fontSize: "12px" }}>
                  View Photos
                </p>
              </Box>
              <Carousel
                responsive={headerSlider}
                renderArrowsWhenDisabled={true}
                infinite={true}
                autoPlay={true}
                removeArrowOnDeviceType={["mobile"]}
              >
                <Box
                  key={1}
                  sx={{
                    borderRadius: "20px",
                    overflow: "hidden",
                    height: "220px",
                    position: "relative",
                    cursor: "pointer",
                    margin: "4px",
                  }}
                  onClick={() => handleModifyDetailOpenModal()}
                >
                  <img
                    src="./images/pool.webp"
                    alt="Villa in Kasauli"
                    width="100%"
                    height="100%"
                    style={{ objectFit: "cover" }}
                    className="imgZoom"
                  />
                </Box>
                <Box
                  key={2}
                  sx={{
                    borderRadius: "20px",
                    overflow: "hidden",
                    height: "220px",
                    cursor: "pointer",
                    margin: "4px",
                  }}
                  onClick={() => handleModifyDetailOpenModal()}
                >
                  <img
                    onClick={() => handleModifyDetailOpenModal()}
                    src="./images/living-room.webp"
                    alt="Hotel in Kasauli"
                    width="100%"
                    height="100%"
                    className="imgZoom"
                    style={{ objectFit: "cover" }}
                  />
                </Box>
                <Box
                  key={3}
                  sx={{
                    borderRadius: "20px",
                    overflow: "hidden",
                    height: "220px",
                    cursor: "pointer",
                    margin: "4px",
                    zIndex: 99,
                  }}
                  onClick={() => handleModifyDetailOpenModal()}
                >
                  <img
                    onClick={() => handleModifyDetailOpenModal()}
                    src="./images/bedroom.webp"
                    alt="Kasauli Resorts"
                    width="100%"
                    height="100%"
                    className="imgZoom"
                    style={{ objectFit: "cover" }}
                  />
                </Box>
              </Carousel>
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid size={12}>
              <h4>
                Salud
                <Link
                  onClick={() => shareLink()}
                  sx={{ color: COLORS.black, padding: "0px 8px" }}
                >
                  <ShareOutlinedIcon />
                </Link>
              </h4>
              <p style={{ opacity: "0.5" }}>Dhako, Himachal Pradesh</p>
              <Box display="flex" gap="24px" marginTop="16px">
                <Box
                  sx={{
                    backgroundColor: "rgba(248, 198, 75, 0.10)",
                    borderRadius: "100px",
                    padding: "8px 24px",
                    display: "flex",
                    gap: "8px",
                    alignItems: "center",
                  }}
                >
                  <p style={{ paddingTop: "4px" }}>4.8</p>
                  <StarRoundedIcon sx={{ height: "24px" }} />
                </Box>
                <Box
                  sx={{
                    border: "1px dashed #000",
                    borderRadius: "100px",
                    padding: "4px 20px",
                    display: "flex",
                    gap: "8px",
                    alignItems: "center",
                  }}
                >
                  <ThumbUpOffAltIcon style={{ fontSize: "16px" }} />
                  <p style={{ fontSize: "12px", lineHeight: "16px" }}>
                    Guest Favourite
                  </p>
                </Box>
              </Box>
              <ul
                style={{
                  backgroundColor: "rgba(248, 198, 75, 0.10)",
                  borderRadius: "8px",
                  padding: "12px 24px",
                  display: "flex",
                  gap: "24px",
                  alignItems: "center",
                  marginTop: "16px",
                  fontSize: "12px",
                }}
              >
                <li key="1">Entire place</li>
                <li key="2">Max. 11 Guests</li>
                <li key="3">4 Rooms</li>
                <li key="4">4 Baths</li>
              </ul>
              <h5 style={{ marginTop: "24px" }}>
                <span style={{ fontWeight: "300" }}>
                  Here why da cations should be your
                </span>{" "}
                first choice!
              </h5>

              {/* Facilities */}
              <Box
                display="flex"
                flexWrap="wrap"
                justifyContent="flex-start"
                gap="16px"
                marginTop="24px"
                className="facilities"
              >
                {facilities?.map((facility, index) => (
                  <Box
                    key={index}
                    flex="1"
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    className="facility"
                    onClick={() => {
                      if (facility.name === "View Menu") {
                        window.open("https://menula.in/Salud", "_blank");
                      }
                    }}
                  >
                    <Box
                      sx={{
                        borderRadius: "100px",
                        backgroundColor: "#FFF4F3",
                        marginBottom: "8px",
                        padding: "4px",
                        height: "50px",
                        width: "50px",
                      }}
                    >
                      <img
                        src={facility.iconUrl}
                        alt={facility.name}
                        width="100%"
                      />
                    </Box>
                    <p
                      style={{
                        maxWidth: "90px",
                        textAlign: "center",
                        fontSize: "12px",
                      }}
                    >
                      {facility.name}
                    </p>
                  </Box>
                ))}
              </Box>
            </Grid>
            <Grid size={12}>
              <Box
                sx={{
                  background:
                    "linear-gradient(122deg, rgba(139, 205, 172, 0.20) 0%, rgba(248, 198, 75, 0.20) 100%)",
                  padding: "16px",
                  borderRadius: "20px",
                }}
              >
                <h4
                  style={{ display: "flex", gap: "4px", alignItems: "center" }}
                >
                  <span style={{ fontSize: "12px" }}>(Starting from)</span>
                  ₹44,500
                </h4>
                <p style={{ opacity: "0.6", marginBottom: "24px" }}>
                  (4 Rooms / 8 Pax / Night + Taxes)
                </p>
                <Grid container spacing={3}>
                  <Grid size={12}>
                    <EVDateInput
                      attrName={"checkIn"}
                      className="datepicker"
                      title="checkIn"
                      value={formData?.checkIn?.value}
                      value_update={(atrrName: string, e: any) =>
                        handleChangeReserve(e, "checkIn")
                      }
                      min_date={0}
                      max_date={-1}
                      date_validation_type="YEARS"
                      warn_status={formData?.checkIn?.warning}
                      error_message="Select checkIn"
                    />
                  </Grid>
                  <Grid size={12}>
                    <EVDateInput
                      attrName={"checkOut"}
                      className="datepicker"
                      title="Check-Out"
                      value={formData?.checkIn?.value}
                      value_update={(atrrName: string, e: any) =>
                        handleChangeReserve(e, "checkOut")
                      }
                      min_date={0}
                      max_date={-1}
                      date_validation_type="YEARS"
                      warn_status={formData?.checkOut?.warning}
                      error_message="Select checkout"
                    />
                  </Grid>
                  <Grid size={6}>
                    <SelectDropdown
                      name="noOfGuests"
                      value={formData?.noOfGuests?.value}
                      handleChange={(e: any) =>
                        handleChangeReserve(e, "noOfGuests")
                      }
                      label="Guests"
                      menuItems={noofguests}
                      warn_status={formData?.noOfGuests?.warning}
                      error_message={"select guests"}
                    />
                  </Grid>
                  <Grid size={6}>
                    <SelectDropdown
                      name="noOfRooms"
                      value={formData?.noOfRooms?.value}
                      handleChange={(e: any) =>
                        handleChangeReserve(e, "noOfRooms")
                      }
                      label="No. of Rooms"
                      menuItems={noofRooms}
                      warn_status={formData?.noOfRooms?.warning}
                      error_message={"select rooms"}
                    />
                  </Grid>
                  <Grid
                    size={{ xs: 12, sm: 8 }}
                    offset={{ sm: 2 }}
                    textAlign="center"
                  >
                    <Buttons
                      text="Reserve"
                      fullWidth={true}
                      onClick={() => {
                        validate_form();
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>

          {/* Section Tabs */}
          <Grid container spacing={3} className="mb-10 pt-8 stickyGrid">
            <Grid size={12} display="flex" gap="12px" overflow="scroll">
              <TabButton
                text="About"
                active={activeTab === "About"}
                onClick={() => handleScrollTo("About")}
              />
              <TabButton
                text="Spaces"
                active={activeTab === "Spaces"}
                onClick={() => handleScrollTo("Spaces")}
              />
              <TabButton
                text="Amenities"
                active={activeTab === "Amenities"}
                onClick={() => handleScrollTo("Amenities")}
              />
              <TabButton
                text="Meals"
                active={activeTab === "Meals"}
                onClick={() => handleScrollTo("Meals")}
              />
              <TabButton
                text="Experiences"
                active={activeTab === "Experiences"}
                onClick={() => handleScrollTo("Experiences")}
              />
              <TabButton
                text="Rules"
                active={activeTab === "Rules"}
                onClick={() => handleScrollTo("Rules")}
              />
              <TabButton
                text="Location"
                active={activeTab === "Location"}
                onClick={() => handleScrollTo("Location")}
              />
              <TabButton
                text="Reviews"
                active={activeTab === "Reviews"}
                onClick={() => handleScrollTo("Reviews")}
              />
              <TabButton
                text="Policy"
                active={activeTab === "Policy"}
                onClick={() => handleScrollTo("Policy")}
              />
              <TabButton
                text="FAQ"
                active={activeTab === "FAQ"}
                onClick={() => handleScrollTo("FAQ")}
              />
            </Grid>
          </Grid>

          {/* About */}
          <Grid container spacing={3} className="mb-10" id="About">
            <Grid size={12}>
              <h5>Explore Your Stay</h5>
              <Box
                sx={{
                  maxHeight: showDescContent ? "100%" : "112px",
                  overflow: "hidden",
                  transition: "all ease-in-out",
                  marginTop: "12px",
                }}
              >
                {explore_your_stay}
              </Box>
              <Link
                style={{
                  color: COLORS.primary,
                  fontSize: "15px",
                  fontWeight: "bold",
                  textDecorationColor: COLORS.primary,
                  cursor: "pointer",
                }}
                onClick={() => {
                  setShowDescContent(!showDescContent);
                }}
              >
                {showDescContent ? "Show Less" : "Read More"}{" "}
              </Link>
            </Grid>
          </Grid>

          {/* Spaces */}
          <Grid container spacing={3} className="mb-10" id="Spaces">
            <Grid size={12}>
              <h4>Spaces</h4>
            </Grid>
            <Grid size={12}>
              <Carousel
                responsive={spaceSlider}
                renderArrowsWhenDisabled={true}
              >
                {Spaces?.map((data, index) => (
                  <Box padding="0px 4px 24px" key={index}>
                    <Box
                      sx={{
                        borderRadius: "20px",
                        overflow: "hidden",
                      }}
                    >
                      <img src={data.image} width="100%" alt="Bedroom" />
                    </Box>
                    <Box
                      sx={{
                        boxShadow: "0px 8px 8px rgba(0,0,0,0.1)",
                        borderRadius: "20px",
                        padding: "20px",
                      }}
                    >
                      <h5>{data.title}</h5>
                      <ul
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "8px",
                          marginTop: "8px",
                        }}
                      >
                        {data.data?.map((sub_data, index) => (
                          <li
                            key={index}
                            style={{
                              listStyle: "none",
                              display: "flex",
                              gap: "8px",
                            }}
                          >
                            <img
                              src="./images/hexagon-tick-icon.png"
                              width="15px"
                              height="17px"
                            />
                            <p>{sub_data}</p>
                          </li>
                        ))}
                      </ul>
                    </Box>
                  </Box>
                ))}
              </Carousel>
            </Grid>
          </Grid>

          {/* Amenities */}
          <Grid container spacing={2} className="mb-10" id="Amenities">
            <Grid size={12}>
              <h4>Amenities</h4>
            </Grid>
            <Grid size={4} textAlign="center">
              <Box>
                <Box borderRadius="20px" overflow="hidden" marginBottom="12px">
                  <img
                    src="./images/wardrobe.jpg"
                    width="100%"
                    alt="wardrobe"
                  />
                </Box>
                <h6>Wardrobe</h6>
              </Box>
            </Grid>
            <Grid size={4} textAlign="center">
              <Box borderRadius="20px" overflow="hidden" marginBottom="12px">
                <img src="./images/speaker.jpg" width="100%" alt="speaker" />
              </Box>
              <h6>Music System/ Speaker</h6>
            </Grid>
            <Grid size={4} textAlign="center">
              <Box borderRadius="20px" overflow="hidden" marginBottom="12px">
                <img
                  src="./images/toiletries.jpg"
                  width="100%"
                  alt="toiletries"
                />
              </Box>
              <h6>Toiletries</h6>
            </Grid>
            <Grid size={{ xs: 4, md: 3, lg: "grow" }} textAlign="center">
              <Box borderRadius="20px" overflow="hidden" marginBottom="12px">
                <img src="./images/mattress.jpg" width="100%" alt="mattress" />
              </Box>
              <h6>Extra Mattress</h6>
            </Grid>
            <Grid size={{ xs: 4, md: 3, lg: "grow" }} textAlign="center">
              <Box borderRadius="20px" overflow="hidden" marginBottom="12px">
                <img src="./images/towles.jpg" width="100%" alt="towles" />
              </Box>
              <h6>Towels</h6>
            </Grid>
          </Grid>

          {/* Meals */}
          <Grid container spacing={3} className="mb-10" id="Meals">
            <Grid size={12}>
              <h4>Meals</h4>
            </Grid>
            <Grid size={{ xs: 12, md: 6 }}>
              <Box borderRadius="20px" overflow="hidden">
                <img src="./images/meal.jpg" width="100%" alt="img" />
              </Box>
            </Grid>
            <Grid size={{ xs: 12, md: 6 }} alignSelf="center">
              <ul
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                  marginLeft: "12px",
                }}
              >
                {meals?.map((data, index) => (
                  <li
                    key={index}
                    style={{
                      color: COLORS.darkgrey,
                      fontSize: "14px",
                      lineHeight: "16px",
                    }}
                  >
                    <strong>{data.title}</strong> - {data.desc}
                  </li>
                ))}
              </ul>
              <Box display="flex" gap="12px" marginTop="20px">
                <Button
                  sx={{
                    border: "1px solid",
                    borderColor: COLORS.darkgrey,
                    color: COLORS.darkgrey,
                    borderRadius: "40px",
                    textTransform: "capitalize",
                    fontSize: "12px",
                    gap: "8px",
                    flex: "1",
                  }}
                >
                  <img
                    src="./images/veg-icon.svg"
                    height="14px"
                    alt="veg food"
                  />
                  Veg
                </Button>
                <Button
                  sx={{
                    border: "1px solid",
                    borderColor: COLORS.darkgrey,
                    color: COLORS.darkgrey,
                    borderRadius: "40px",
                    textTransform: "capitalize",
                    fontSize: "14px",
                    gap: "8px",
                    flex: "1",
                  }}
                >
                  <img
                    src="./images/mix-menu-icon.svg"
                    height="14px"
                    alt="non-veg food"
                  />
                  Non-Veg
                </Button>
                <Button
                  sx={{
                    border: "1px solid",
                    borderColor: COLORS.darkgrey,
                    color: COLORS.darkgrey,
                    borderRadius: "40px",
                    textTransform: "capitalize",
                    fontSize: "14px",
                    gap: "8px",
                    flex: "1",
                  }}
                >
                  <a
                    href="https://menula.in/Salud"
                    target="_blank"
                    style={{
                      color: "inherit", // inherits color from parent (Button)
                      textDecoration: "none", // removes underline
                    }}
                  >
                    View Menu
                  </a>
                </Button>
              </Box>
            </Grid>
          </Grid>

          {/* Experiences */}
          <Grid
            container
            spacing={2}
            className="mb-10"
            id="Experiences"
            justifyContent="center"
          >
            <Grid size={12}>
              <h4>Experiences</h4>
            </Grid>
            <Grid size={{ xs: 6, md: 4 }} textAlign="center">
              <Box border="1px solid #8BCDAC" borderRadius="24px">
                <Box borderRadius="20px" overflow="hidden" margin="8px">
                  <img
                    src="./images/experience-1.jpg"
                    width="100%"
                    alt="adventure"
                  />
                </Box>
              </Box>
            </Grid>
            <Grid size={{ xs: 6, md: 4 }} textAlign="center">
              <Box border="1px solid #8BCDAC" borderRadius="24px">
                <Box borderRadius="20px" overflow="hidden" margin="8px">
                  <img
                    src="./images/experience-2.jpg"
                    width="100%"
                    alt="bonfire"
                  />
                </Box>
              </Box>
            </Grid>
            <Grid size={{ xs: 6, md: 4 }} textAlign="center">
              <Box border="1px solid #8BCDAC" borderRadius="24px">
                <Box borderRadius="20px" overflow="hidden" margin="8px">
                  <img
                    src="./images/experience-3.jpg"
                    width="100%"
                    alt="karaoke night"
                  />
                </Box>
              </Box>
            </Grid>
            <Grid size={12}>
              <p>
                Whether you're seeking relaxation, adventure, or simply a break
                from the ordinary, dacations's immersive experiences tailored
                meet your preferences. Have a private celebration or just enjoy
                a bonfire and karaoke night under the stars.
              </p>
            </Grid>
          </Grid>

          {/* House Rules */}
          <Box
            boxShadow="0px 12px 12px rgba(0,0,0,0.1)"
            borderRadius="20px"
            padding="20px"
            marginBottom="40px"
            id="Rules"
          >
            <Grid container spacing={3}>
              <Grid size={12}>
                <h4>Home Rules and Truths</h4>
                <ul
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "12px",
                    marginTop: "24px",
                    marginBottom: "24px",
                    paddingLeft: "12px",
                    maxHeight: showRulesContent ? "100%" : "260px",
                    overflow: "hidden",
                    transition: "all ease-in-out",
                  }}
                >
                  {rules?.map((Rules, index) => (
                    <li
                      key={index}
                      style={{
                        color: COLORS.darkgrey,
                        fontSize: "14px",
                        lineHeight: "16px",
                      }}
                    >
                      {Rules.desc}
                    </li>
                  ))}
                </ul>
                <Link
                  style={{
                    color: COLORS.primary,
                    fontSize: "16px",
                    fontWeight: "bold",
                    textDecorationColor: COLORS.primary,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setShowRulesContent(!showRulesContent);
                  }}
                >
                  {showRulesContent ? "Show Less" : "Read More"}{" "}
                </Link>
              </Grid>
            </Grid>
          </Box>

          {/* Location */}
          <Grid container spacing={3} className="mb-10" id="Location">
            <Grid size={12}>
              <h4>Location</h4>
            </Grid>
            <Grid size={12}>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13698.374954091367!2d77.0783136!3d30.8700474!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390f85353108b9b5%3A0x2c88055be14ee7f4!2sSalud!5e0!3m2!1sen!2sin!4v1708431190473!5m2!1sen!2sin"
                width="100%"
                height="320"
                allowFullScreen={true}
                loading="lazy"
                style={{
                  border: "none",
                  borderRadius: "20px",
                  marginBottom: "12px",
                }}
              ></iframe>
              {/* <p>
                An escapist hill station surrounded by green valleys and
                waterfalls, Lonavala is one of the most sought-after tourist
                getaways near Mumbai and Pune. It is a great way to skip town
                and live in the lap
              </p> */}
            </Grid>
          </Grid>

          {/* Reviews */}
          <Box
            boxShadow="0px 12px 12px rgba(0,0,0,0.1)"
            borderRadius="20px"
            padding="12px"
            marginBottom="40px"
            id="Reviews"
          >
            <Grid container spacing={3}>
              <Grid size={12}>
                <h4>Reviews</h4>
                <p>"Explore our products, experience our benefits"</p>
              </Grid>
              <Grid size={12}>
                <Carousel
                  responsive={reviewslider}
                  renderArrowsWhenDisabled={true}
                >
                  {Reviews?.map((Reviews, index) => (
                    <Box
                      key={index}
                      display="flex"
                      flexDirection="column"
                      gap="12px"
                      marginBottom="24px"
                      padding="0px 6px"
                    >
                      <Box
                        sx={{
                          backgroundColor: COLORS.darkgrey,
                          color: COLORS.white,
                          display: "flex",
                          gap: "32px",
                          alignItems: "center",
                          padding: "20px",
                          borderRadius: "12px",
                          marginBottom: "12px",
                        }}
                      >
                        <Box
                          height="78px"
                          width="78px"
                          borderRadius="100px"
                          overflow="hidden"
                        >
                          <img src={Reviews.imgUrl} width="100%" alt="img" />
                        </Box>
                        <Box>
                          <h5>{Reviews.name}</h5>
                          <p
                            style={{
                              fontSize: "16px",
                              color: COLORS.white,
                              fontWeight: "300",
                              marginTop: "12px",
                            }}
                          >
                            - {Reviews.designation}
                          </p>
                        </Box>
                      </Box>
                      <h6>"{Reviews.feedbackTitle}"</h6>
                      <Box sx={{ color: COLORS.primary }}>
                        <Rating
                          name="size-large"
                          readOnly
                          defaultValue={Reviews.rating}
                          size="large"
                        />
                      </Box>
                      <p>{Reviews.feedback}</p>
                    </Box>
                  ))}
                </Carousel>
              </Grid>
            </Grid>
          </Box>

          {/* Policy */}
          <Box
            boxShadow="0px 12px 12px rgba(0,0,0,0.1)"
            borderRadius="20px"
            padding="20px"
            marginBottom="44px"
            id="Policy"
          >
            <Grid container spacing={3}>
              <Grid size={12}>
                <h4>Policy</h4>
                <ul
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "12px",
                    marginTop: "24px",
                    marginBottom: "24px",
                    paddingLeft: "12px",
                    maxHeight: showPolicyContent ? "100%" : "260px",
                    overflow: "hidden",
                    transition: "all ease-in-out",
                  }}
                >
                  {policy?.map((Policy, index) => (
                    <li
                      key={index}
                      style={{
                        color: COLORS.darkgrey,
                        fontSize: "14px",
                        lineHeight: "16px",
                      }}
                    >
                      {Policy.desc}
                    </li>
                  ))}
                </ul>
                <Link
                  style={{
                    color: COLORS.primary,
                    fontSize: "16px",
                    fontWeight: "bold",
                    textDecorationColor: COLORS.primary,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setShowPolicyContent(!showPolicyContent);
                  }}
                >
                  {showPolicyContent ? "Show Less" : "Read More"}{" "}
                </Link>
              </Grid>
            </Grid>
          </Box>

          {/* FAQ */}
          <Box
            id="FAQ"
            sx={{
              borderRadius: "20px",
              padding: "12px",
              backgroundColor: "#F6F7F8",
            }}
          >
            <Grid container spacing={3}>
              <Grid size={12}>
                <h4>
                  <span style={{ fontWeight: "300" }}>Frequently Asked</span>{" "}
                  Questions
                </h4>
              </Grid>
              <Grid size={12}>
                {faqs?.map((faq) => (
                  <Accordion
                    key={faq.id}
                    expanded={expanded === faq.id}
                    onChange={handleChangeFaq(faq.id)}
                    sx={{
                      borderRadius: "12px",
                      border: "1px solid #ccc",
                      boxShadow: "0px 0px 0px rgba(0,0,0,0)",
                      marginBottom: "16px",
                      "&.Mui-expanded": {
                        boxShadow: "0px 4px 12px rgba(0,0,0,0.1)",
                      },
                      "&:first-of-type": {
                        borderTopLeftRadius: "12px",
                        borderTopRightRadius: "12px",
                      },
                      "&:last-of-type": {
                        borderBottomLeftRadius: "12px",
                        borderBottomRightRadius: "12px",
                      },
                      "&:before": {
                        display: "none",
                      },
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`${faq.id}-content`}
                      id={`${faq.id}-header`}
                    >
                      <h6>{faq.question}</h6>
                    </AccordionSummary>
                    <AccordionDetails>
                      <p>{faq.answer}</p>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </Grid>
            </Grid>
          </Box>
        </Box>
        <MPhotoTourPopup
          open={isModifyDetailModalOpen}
          onclose={handleModifyDetailCloseModal}
        />
      </Box>
      <Footer />
    </>
  );
};

export default MDaCations;
