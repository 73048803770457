import { Box, TextField } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useState } from "react";
import { COLORS } from "../../../SupportingFiles/colors";
import Popup from "../../Desktop/Popup/Popup";
import Buttons from "../../InputFields/Buttons/Buttons";

function Navbar() {
  // const [isHelpModalOpen, setHelpModalOpen] = useState(false);

  // const handleHelpOpenModal = () => {
  //   setHelpModalOpen(true);
  // };
  // const handleHelpCloseModal = () => {
  //   setHelpModalOpen(false);
  // };

  return (
    <Box
      sx={{
        backgroundColor: COLORS.white,
        borderBottom: "1px solid",
        borderBottomColor: COLORS.primary,
        padding: "8px 12px",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "fixed",
        top: "0px",
        zIndex: "991",
      }}
    >
      <Grid
        container
        spacing={3}
        alignItems="center"
        maxWidth="100%"
        width="100%"
      >
        <Grid size={4}>
          <img
            src="./images/salud-logo.svg"
            height="42px"
            alt="Salud by DaCations"
          />
        </Grid>
        <Grid size={8} justifyContent="end" display="flex">
          {/* <Button
            sx={{
              border: "1px solid #25CC64",
              borderRadius: "8px",
              padding: "8px",
              color: "#25CC64",
              minWidth: "20px",
              minHeight: "20px",
              marginRight: "20px",
            }}
            onClick={openWhatsApp}
          >
            <WhatsAppIcon style={{ height: "16px", width: "16px" }} />
          </Button>
          <Button
            sx={{
              border: "1px dashed #000",
              borderRadius: "8px",
              fontSize: "14px",
              fontWeight: "600",
              padding: "8px 16px",
              lineHeight: "16px",
              color: COLORS.black,
              textTransform: "capitalize",
            }}
            onClick={handleHelpOpenModal}
          >
            Need Help?
          </Button> */}
          {/* <Popup
            open={isHelpModalOpen}
            onClose={handleHelpCloseModal}
            class_name="smallView"
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                gap: "80px",
                padding: "8px 24px",
                maxWidth: "1200px",
                width: "100%",
                margin: "0px auto",
              }}
            >
              <Grid container spacing={3} alignItems="flex-start" width="100%">
                <Grid size={12}>
                  <h4>Need Help?</h4>
                  <p>We are happy to help you</p>
                </Grid>
                <Grid size={12}>
                  <TextField
                    id="outlined-basic"
                    label="Mobile Number"
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid size={12} textAlign="center">
                  <Buttons text="Request Call Back" onClick={() => {}} />
                </Grid>
              </Grid>
            </Box>
          </Popup> */}
        </Grid>
      </Grid>
    </Box>
  );
}

export default Navbar;
