import { useState, useEffect } from "react";

const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(false); // Initially, assume it's not mobile

  useEffect(() => {
    // Check if window is available (client-side)
    if (typeof window !== "undefined") {
      const handleResize = () => {
        setIsMobile(window.innerWidth < 900);
      };

      // Set up an event listener for window resize
      window.addEventListener("resize", handleResize);

      // Call the handleResize function once on initial mount
      handleResize();

      // Clean up the event listener when the component unmounts
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, []);

  return isMobile;
};

export default useIsMobile;
