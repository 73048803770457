import { useState } from "react";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import KeyboardArrowLeftRoundedIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import { Box } from "@mui/material";
import { COLORS } from "../../SupportingFiles/colors";

interface Photo_slider {
  imgUrl: string;
}

const MSinglePhotoView = ({
  onclose,
  selectedImageIndex,
}: {
  onclose: () => void;
  selectedImageIndex: number;
}) => {
  const [currentImageIndex, setCurrentImageIndex] =
    useState(selectedImageIndex);

  const photo_slider: Photo_slider[] = [
    { imgUrl: "./images/living-room.webp" },
    { imgUrl: "./images/living-room-3.webp" },
    { imgUrl: "./images/living-room-2.webp" },
    { imgUrl: "./images/dining-area-1.webp" },
    { imgUrl: "./images/dining-area-2.webp" },
    { imgUrl: "./images/bedroom-1.webp" },
    { imgUrl: "./images/bedroom-5.webp" },
    { imgUrl: "./images/bedroom-6.webp" },
    { imgUrl: "./images/bedroom-7.webp" },
    { imgUrl: "./images/bedroom-2.webp" },
    { imgUrl: "./images/bedroom-8.webp" },
    { imgUrl: "./images/bedroom-9.webp" },
    { imgUrl: "./images/bedroom-3.webp" },
    { imgUrl: "./images/bedroom.webp" },
    { imgUrl: "./images/bedroom-10.webp" },
    { imgUrl: "./images/bedroom-11.webp" },
    { imgUrl: "./images/bedroom-13.webp" },
    { imgUrl: "./images/bedroom-14.webp" },
    { imgUrl: "./images/bedroom-12.webp" },
    { imgUrl: "./images/bedroom-15.webp" },
    { imgUrl: "./images/bedroom-16.jpeg" },
    { imgUrl: "./images/full-bathroom-1.webp" },
    { imgUrl: "./images/full-bathroom-2.webp" },
    { imgUrl: "./images/full-bathroom-5.webp" },
    { imgUrl: "./images/full-bathroom-3.webp" },
    { imgUrl: "./images/full-bathroom-6.webp" },
    { imgUrl: "./images/full-bathroom-7.webp" },
    { imgUrl: "./images/full-bathroom-4.webp" },
    { imgUrl: "./images/full-bathroom-8.webp" },
    { imgUrl: "./images/balcony.webp" },
    { imgUrl: "./images/terrace.webp" },
    { imgUrl: "./images/terrace-2.webp" },
    { imgUrl: "./images/pool-2.webp" },
    { imgUrl: "./images/pool-3.webp" },
    { imgUrl: "./images/pool-4.webp" },
    { imgUrl: "./images/entry.webp" },
    { imgUrl: "./images/additional-photo-2.webp" },
    { imgUrl: "./images/additional-photo.webp" },
  ];
  const handlePrevImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : photo_slider.length - 1
    );
  };

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex < photo_slider.length - 1 ? prevIndex + 1 : 0
    );
  };
  return (
    <Box
      sx={{
        backgroundColor: COLORS.black,
        height: "100%",
        width: "100%",
        position: "fixed",
        top: "0px",
        left: "0px",
        zIndex: "9921",
        display: "block",
      }}
    >
      {/* Header */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "24px",
          padding: "12px",
        }}
      >
        {/* Close button */}
        <Box
          onClick={() => onclose()}
          sx={{
            width: "100px",
            height: "40px",
            color: "#fff",
            display: "flex",
            alignItems: "center",
            gap: "8px",
            cursor: "pointer",
            "&:hover": {
              color: COLORS.primary,
            },
          }}
        >
          <CloseRoundedIcon /> Close
        </Box>
        {/* Image count */}
        <Box
          sx={{
            color: "#fff",
            flex: "1",
            textAlign: "center",
            paddingRight: "100px",
          }}
        >
          <h5>
            {currentImageIndex + 1} / {photo_slider.length}
          </h5>
        </Box>
      </Box>

      {/* Image container */}
      <Box
        sx={{
          position: "relative",
          overflow: "hidden",
          height: "calc(100vh - 200px)",
        }}
      >
        {photo_slider?.map((photo, index) => (
          <img
            key={index}
            src={photo.imgUrl}
            alt={`img-${index}`}
            style={{
              position: "absolute",
              top: 0,
              transform: `translateX(${
                index === currentImageIndex ? 0 : "100%"
              })`,
              transition: "transform 0.3s ease-in-out",
              height: "100%",
              width: "100%",
              objectFit: "contain",
            }}
          />
        ))}
      </Box>

      {/* Navigation buttons */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "24px",
          padding: "24px",
        }}
      >
        {/* Previous button */}
        <Box
          sx={{
            width: "40px",
            height: "40px",
            border: "1px solid #fff",
            borderRadius: "8px",
            color: "#fff",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            "&:hover": {
              color: COLORS.primary,
              borderColor: COLORS.primary,
            },
          }}
          onClick={handlePrevImage}
        >
          <KeyboardArrowLeftRoundedIcon style={{ fontSize: "24px" }} />
        </Box>
        {/* Next button */}
        <Box
          sx={{
            width: "40px",
            height: "40px",
            border: "1px solid #fff",
            borderRadius: "8px",
            color: "#fff",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            "&:hover": {
              color: COLORS.primary,
              borderColor: COLORS.primary,
            },
          }}
          onClick={handleNextImage}
        >
          <KeyboardArrowRightRoundedIcon style={{ fontSize: "24px" }} />
        </Box>
      </Box>
    </Box>
  );
};

export default MSinglePhotoView;
