import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import React from "react";
import { COLORS } from "../../../SupportingFiles/colors";

interface SelectDropdownProps {
  label: string;
  name?: string;
  handleChange?: any;
  value?: any;
  error_message?: any;
  warn_status?: any;
  menuItems: { value: number | string; label: React.ReactNode }[];
}

const SelectDropdown: React.FC<SelectDropdownProps> = (props) => {
  const [selectedValue, setSelectedValue] = React.useState("");

  // const handleChange = (event: SelectChangeEvent) => {
  //   setSelectedValue(event.target.value);
  // };

  return (
    <FormControl
      variant="outlined"
      fullWidth
      sx={{
        height: "56px",
        backgroundColor: COLORS.white,
        position: "relative",
      }}
    >
      <InputLabel
        sx={{
          color: COLORS.darkgrey,
          "&.Mui-focused": {
            color: COLORS.darkgrey,
          },
        }}
      >
        {props.label}
      </InputLabel>
      <Select
        name={props.name}
        value={props.value}
        onChange={props.handleChange}
        label={props.label}
        IconComponent={KeyboardArrowDownIcon}
        sx={{
          height: "56px",
          ".MuiSelect-select": {
            color: COLORS.darkgrey,
            textAlign: "left",
            "&:focus": {
              backgroundColor: "transparent",
            },
          },
          ".MuiSvgIcon-root": {
            color: COLORS.darkgrey,
          },
          "&:before": {
            borderColor: COLORS.darkgrey,
            height: "0px",
            borderRadius: "4px",
          },
          "&:hover": {
            "&:before": {
              borderColor: "#DF9F39!important",
            },
          },
          "&:after": {
            borderColor: COLORS.darkgrey,
            height: "0px",
            borderRadius: "4px",
          },
        }}
      >
        {props.menuItems?.map((item) => (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
      {props.warn_status ? (
        <span className="error">{props.error_message}</span>
      ) : null}
    </FormControl>
  );
};

export default SelectDropdown;
