import { Box, TextField } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useEffect, useState } from "react";
import { COLORS } from "../../../SupportingFiles/colors";
import Buttons from "../../InputFields/Buttons/Buttons";
import Popup from "../Popup/Popup";

function Navbar() {
  const [isHelpModalOpen, setHelpModalOpen] = useState(false);
  const [logoHeight, setLogoHeight] = useState<number>(120); // Initial height of dacations-logo.svg

  const handleHelpOpenModal = () => {
    setHelpModalOpen(true);
  };
  const handleHelpCloseModal = () => {
    setHelpModalOpen(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      const newHeight = 120 - window.scrollY / 2; // Decrease height on scroll
      setLogoHeight(newHeight >= 80 ? newHeight : 80); // Minimum height is 60px
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: COLORS.white,
        borderBottom: "1px solid",
        borderBottomColor: COLORS.primary,
        padding: "8px 24px",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "fixed",
        top: "0px",
        zIndex: "991",
      }}
    >
      <Grid container spacing={3} alignItems="center" size="grow">
        <Grid size={6}>
          <img
            src="./images/salud-logo.svg"
            height="72px"
            alt="Salud by DaCations"
          />
        </Grid>
        <Grid size={6} textAlign="right">
          {/* <Button
            sx={{
              border: "1px solid #25CC64",
              borderRadius: "8px",
              padding: "8px",
              color: "#25CC64",
              minWidth: "20px",
              marginRight: "24px",
            }}
            onClick={openWhatsApp}
          >
            <WhatsAppIcon />
          </Button> */}
          {/* <Button
            sx={{
              border: "1px dashed #000",
              borderRadius: "8px",
              fontSize: "14px",
              fontWeight: "600",
              padding: "8px 16px",
              lineHeight: "16px",
              color: COLORS.black,
              textTransform: "capitalize",
            }}
            onClick={handleHelpOpenModal}
          >
            Need Help?
          </Button> */}
          <Popup
            open={isHelpModalOpen}
            onClose={handleHelpCloseModal}
            class_name="smallView"
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                padding: "8px 24px",
                gap: "80px",
                maxWidth: "1200px",
                width: "100%",
                margin: "0px auto",
              }}
            >
              <Grid container spacing={3} alignItems="flex-start" width="100%">
                <Grid size={12}>
                  <h4>Need Help?</h4>
                  <p>We are happy to help you</p>
                </Grid>
                <Grid size={12}>
                  <TextField
                    id="outlined-basic"
                    label="Mobile Number"
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid size={12} textAlign="center">
                  <Buttons text="Request Call Back" onClick={() => {}} />
                </Grid>
              </Grid>
            </Box>
          </Popup>
        </Grid>
      </Grid>
      <img
        src="./images/dacations-logo.svg"
        height={`${logoHeight}px`}
        alt="DaCations in Kasauli"
        style={{
          position: "absolute",
          bottom: `-${logoHeight / 2}px`,
          left: `calc(50% - ${logoHeight / 2}px)`,
        }}
      />
    </Box>
  );
}

export default Navbar;
